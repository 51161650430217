@import url('https://fonts.googleapis.com/css?family=Dongle');

body {
    /*background-image: url('/img/bricks.svg');*/
    background-color: #0a0a0a;
}

/* disabling bootstrap box-sizing override */
* {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
*:before,
*:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.App {
    text-align: center;
    position: relative;

    font-family: Dongle;
    font-weight: 400;
    color: white;
}

.header-img-container {
    position: relative;
    margin: auto;
    margin-top: 3rem;
    max-width: 30rem;
}
.header-img1 {
    position: relative;
    top: 0rem;
    left: 0;
    width: 30rem;
    border: 1px #ddd solid;
}
.header-img2 {
    position: absolute;
    top: 8.25rem;
    left: 25rem;
    image-rendering: pixelated;
    width: 5rem;
    /*border: 1px green solid;*/
}

#header {
    display: flex;
}

#main_container {
    max-width: 2030px;

    margin-left: auto;
    margin-right: auto;
}

.top_logo {
    display: flex;
    flex-direction: row;
    width: 100px;
}

.top_logo_text {
    font-size: 8rem;
    line-height: 11rem;
}

.top_logo_img {
    padding-left: 0.2rem;
    padding-top: 0.2rem;

    position: relative;
    display: block;
    left: 0;

    max-width: 340px;
    width: 8rem;
    height: 8rem;
    image-rendering: pixelated;
}

#ms_social_icons_top {
    display: flex;
    margin-left: auto;
    margin-right: 3rem;
    margin-top: 0;
}

#ms_social_icons_top a:any-link {
    color: white;
}

#ms_social_icons_top_twitter {
    width: 3rem;
    margin-top: 0;
}

#ms_social_icons_top_discord {
    width: 3rem;
    padding-left: 1rem;
}

.main_nav {
    font-size: 3rem;
    position: absolute;
    z-index: 999;
    line-height: 3rem;

    padding-top: 0rem;
    padding-left: 0.5rem;
}

.main_nav li {
    list-style-type: none;
    text-align: left;
}

.main_nav a:any-link {
    text-decoration: none;
    color: white;
}

.gameButton {
    width: 12.5rem;
    margin: 0.4rem;
}

.tokenInput {
    margin: 1rem;
    font-weight: 300;
}

#upper_section {
    padding-top: 2rem;
}

.us_carousel_item {
    outline: none;
}

.us_carousel_item img {
    width: auto;
    margin: auto;
    margin-top: auto;
}

.achoo_sneeze {
    height: auto;
}

#minting_info {
    padding-top: 5rem;
    font-size: 5rem;
}

#middle_section {
    padding-top: 14rem;
}

#ms_content {
    display: flex;
    align-items: center;
    justify-content: center;
}

#ms_img {
    flex-basis: 40%;
}

#ms_text {
    text-align: left;
    padding-left: 7.5rem;

    margin-right: 4rem;
}

.ms_text_header {
    font-size: 6rem;
}
/* this is for handling smaller screen sizes and making the header break better */
@media (min-width: 0px) and (max-width: 1415px) {
    .ms_text_header {
        max-width: 25rem;
    }
}

.ms_text_story {
    font-size: 2.3rem;
    max-width: 60rem;
    line-height: 2.75rem;
}

#footer {
    padding-top: 16rem;
    padding-bottom: 1rem;
    font-size: 2rem;
}

#pharmacy_mint_countdown {
    font-size: 4rem;
}

.main-content {
    font-size: 2.3rem;
    margin-left: auto;
    margin-right: auto;
}

#pharmacy_mint_minting_details {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
}

.status_error {
    color: red;
}

.status_success {
    color: greenyellow;
}

.bb-input {
    font-size: 1.9rem;
}

.btn-theme {
    font-size: 2.3rem;
    background-color: rgb(88, 152, 203);
    border-color: rgb(164, 252, 253);
}

.btn-theme:hover {
    background-color: rgb(124, 202, 253);
    border-color: rgb(164, 252, 253);
}

/*.btn-theme:disabled {*/
/*    color: #fff;*/
/*    background-color: #89344B;*/
/*    border-color: #89344B;*/
/*}*/

.inline-button {
    /*font-size: 2.3rem;*/
    margin-left: 1rem;
}

textarea.sig-area {
    min-height: 15rem;
}

.no-upper-case {
    text-transform: none;
}

#pharmacy_verified_contract_details {
    display: inline-block;
    text-align: left;
    font-size: 2rem;
    padding-top: 7.5rem;
    line-height: 1.5rem;
    text-transform: uppercase;
}

#pharmacy_verified_contract_details a:any-link {
    color: white;
    text-transform: none;
}

#etherscan_logo {
    width: 2.6rem;
    padding-left: 1rem;
}

#mission_text {
    font-size: 2.3rem;
    max-width: 60rem;
    line-height: 2.75rem;
    text-align: left;
    margin-left: 3rem;
}

.primary-controls {
    text-align: center;
    margin-top: 2rem;
}

.top-charts {
    margin-left: auto;
    margin-right: auto;
    font-size: 2.3rem;
}

.submit-score {
    margin-top: 6rem;
}

#primary_sale_dropdown_container {
    display: flex;
    justify-content: center;

    margin-bottom: 2rem;
}

#primary_sale_dropdown_dd {
    font-size: 2rem;
    margin-left: 2rem;
    padding: 0;
}
